import gql from 'graphql-tag'

export const GET_LETTER_FILTER = gql`
  query LetterFilter($filterId: ID!) {
    balanceFilter(filterId: $filterId) {
      id
      minBalance
      maxBalance
      allowL30
      allowL60
      allowL90
      allowG90
    }

    account {
      billing_workflow {
        id
        start_letterExcludeDigital
      }
    }
  }
`

export const UPDATE_LETTER_FILTER = gql`
  mutation UpdateLetterFilter(
    $filterId: ID!
    $balanceFilter: PartialBalanceFilterInput!
    $start_letterExcludeDigital: Boolean!
  ) {
    updateBalanceFilter(filterId: $filterId, balanceFilter: $balanceFilter) {
      id
      minBalance
      maxBalance
      allowL30
      allowL60
      allowL90
      allowG90
    }

    toggleBillingWorkflowStartLetterExcludeDigital(start_letterExcludeDigital: $start_letterExcludeDigital) {
      id
      start_letterExcludeDigital
    }
  }
`
